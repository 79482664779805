import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Intro from '../components/sections/intro'
import Partners from '../components/sections/partners'
import ClickWall from '../components/sections/click-wall'

const ThankYou = ({ location }) => {
  React.useEffect(() => {
    window?.ga &&
      window.ga('send', 'screenview', {
        appName: 'medicarecompanion.org',
        screenName: 'thank',
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Layout phone="866-740-2901" location={location}>
      <SEO
        title="Home"
        keywords={[
          `medicare`,
          `health`,
          `insurance`,
          `plans`,
          `affordable`,
          `supplement`,
        ]}
      />
      <Header phone="866-740-2901" hideCta showGen />
      <Intro
        classNames="title-content-bg"
        colType="full-col full-col-pt"
        title="We've Also Matched You With These Top Insurance Companies"
        subTitle="Click at least 2 to 3 companies below to find the best rate!"
      />
      <main id="main">
        <ClickWall />
        <Partners />
      </main>
    </Layout>
  )
}

export default ThankYou
